<template>
  <div class="user">
    <!-- <div class="order_top"> -->
    <!-- <div class="order_top_name">
          <span>订单收款概览</span>
        </div>
        <div class="order_top_flex">
          <span v-if="id" class="po_compny"
            >2023年至今{{ companyinfo.companyFullName }}所有订单</span
          >
          <img v-if="id" width="120" height="120" :src="companyinfo.companyLogo" alt="" />
          <img
            v-else
            class="img_radius"
            src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/9d5474753c55c4839446dcc657912ac.png"
            alt=""
          /> -->
    <!-- <div class="order_top_flex_left">
            <div class="order_top_flex_top">
              <span> 4月您预计获得（元）</span>
              <span class="blue_money"> 6300.35</span>
            </div>
            <div class="order_top_flex_bottom">
              <span> 4月平台获得（元）</span>
              <span class="hui_money"> 6300.35</span>
            </div>
          </div>
          <div class="order_top_flex_right">
            <div class="order_top_flex_top">
              <span> 您往期获得（元）</span>
              <span class="hui_money"> 6300.35</span>
            </div>
            <div class="order_top_flex_bottom">
              <span> 您往期已结算（元）</span>
              <span class="hui_money"> 6300.35</span>
            </div>
          </div>
          <div class="border_left"></div>

          <div class="round_order">
            <span style="font-size: 14px">订单数</span>
            <span class="order_num">100</span>
          </div>
          <div class="round_order_right">
            <span>成功（条）</span>
            <span class="order_num">100</span>
          </div>
          <div class="round_order_right">
            <span>平台成功收款（元）</span>
            <span class="order_num">100</span>
          </div>
          <div class="round_order_right">
            <span class="order_hui">退款（条）</span>
            <span class="order_hui">100</span>
          </div>
          <div class="round_order_right">
            <span class="order_hui">平台退款（元）</span>
            <span class="order_hui">100</span>
          </div> -->
    <!-- </div>
      </div>  -->

    <div class="form_info">
      <el-form ref="form" :model="queryInfo" label-width="100px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="订单类型">
              <el-radio-group v-model="queryInfo.orderType" @change="orderTypechange">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button label="线索订单">甄选对接</el-radio-button>
                <el-radio-button label="专家订单">专家咨询</el-radio-button>
                <el-radio-button label="人才订单">人才服务</el-radio-button>

                <!-- <el-radio-button label="活动订单">活动订单</el-radio-button>
                <el-radio-button label="线索订单">线索订单</el-radio-button>
                <el-radio-button label="专家订单">专家订单</el-radio-button>
                <el-radio-button label="报告订单">报告订单</el-radio-button>
                <el-radio-button label="人才订单">人才订单</el-radio-button>
                <el-radio-button label="艾媒订单">艾媒订单</el-radio-button>
                <el-radio-button label="诊断订单">诊断订单</el-radio-button>
                <el-radio-button label="混合订单">混合订单</el-radio-button>
                <el-radio-button label="个人会员订单">个人会员订单</el-radio-button>
                <el-radio-button label="企业会员订单">企业会员订单</el-radio-button> -->
              </el-radio-group>
              <!-- <el-radio-group v-model="queryInfo.orderMember" @change="orderTypechange">
                <el-radio-button label="会员订单">会员订单</el-radio-button>
              </el-radio-group> -->
            </el-form-item>
            <el-form-item label="订单进度">
              <el-radio-group
                v-model="queryInfo.orderSchedule"
                @change="orderTypechange('orderSchedule')"
              >
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button :label="1">执行中</el-radio-button>
                <el-radio-button :label="2">已完结</el-radio-button>
                <el-radio-button :label="3">已终止</el-radio-button>
                <!-- <el-radio-button :label="3">已退款</el-radio-button> -->
              </el-radio-group>
              <!-- <el-radio-group v-model="queryInfo.state" @change="orderTypechange">
                <el-radio-button label="1">未支付</el-radio-button>
              </el-radio-group> -->
            </el-form-item>
            <!-- <el-form-item label="订单渠道">
              <el-radio-group v-model="queryInfo.orderChannel" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button :label="1">平台订单</el-radio-button>
                <el-radio-button :label="2">小程序订单</el-radio-button>
              </el-radio-group>
            </el-form-item> -->

            <el-form-item label=" " label-width="22px">
              <div class="input-with">
                <div>
                  <el-input
                    placeholder="请输入订单编号、付款公司、付款人"
                    v-model="queryInfo.keys"
                    class="input-with-select"
                  >
                    <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <div class="tab_txt" v-loading="loading">
      <div v-if="listData.length > 0">
        <div style="display: flex; align-items: center; padding-bottom: 10px">
          <div style="padding-right: 50px">
            <span>当前条件下共查出{{ total }}条订单</span>
          </div>
          <div
            v-for="(item, index) in orderList"
            :key="index"
            style="display: flex; align-items: center; margin-right: 30px"
          >
            {{ item.name }}
            <div class="main">
              <span
                class="arrUp"
                @click="clickUp1(item, index)"
                :style="{ 'border-bottom-color': item.color1 }"
              ></span>
              <span
                class="arrDown"
                @click="clickDown1(item, index)"
                :style="{ 'border-top-color': item.color2 }"
              ></span>
            </div>
          </div>
        </div>

        <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
          <!-- <div class="item_checkbox">
          <el-checkbox
            @change="latechange($event, item)"
            v-model="item.checked1"
            label=""
          ></el-checkbox>
        </div> -->
          <div class="storeCenter_item_top">
            <div class="storeCenter_item_top_left">
              <div class="storeCenter_item_top_left_flex">
                <div class="storeCenter_item_top_left_order">
                  <div class="order_name am" v-if="item.orderType == '艾媒订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name rc" v-if="item.orderType == '人才订单'">
                    <!-- {{ item.orderType }} -->
                    人才服务
                  </div>
                  <div class="order_name xs" v-if="item.orderType == '线索订单'">
                    <!-- {{ item.orderType }} -->
                    甄选对接
                  </div>
                  <div class="order_name zj" v-if="item.orderType == '专家订单'">
                    <!-- {{ item.orderType }} -->
                    专家咨询
                  </div>
                  <div class="order_name hd" v-if="item.orderType == '活动订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name zd" v-if="item.orderType == '诊断订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name zd" v-if="item.orderType == '混合订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name hy" v-if="item.orderType == '个人会员订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name hy" v-if="item.orderType == '企业会员订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name bg" v-if="item.orderType == '报告订单'">
                    {{ item.orderType }}
                  </div>

                  <div class="order_num">{{ item.orderNumber }}</div>
                  <div class="state_one" v-if="item.state == 1">
                    <span>未支付</span>
                  </div>
                  <div class="state_two" v-if="item.state == 2">
                    <span>￥{{ item.activePrice }}</span>
                  </div>
                </div>
                <div class="storeCenter_item_top_left_order" style="margin-top: 10px">
                  <div class="compny_name">订单名称：</div>
                  <div>{{ item.orderName }}</div>
                </div>
                <div class="storeCenter_item_top_left_order" style="padding: 14px 0px">
                  <div class="compny_name">付款人/企业：</div>
                  <div>{{ item.realName }}</div>
                  <div v-if="item.realName && item.companyName">/</div>
                  <div>
                    {{ item.companyName }}
                  </div>
                </div>
                <div class="storeCenter_item_top_left_order">
                  <div class="storeCenter_item_top_left_order">
                    <div class="compny_name">创建时间：</div>
                    <div>{{ item.createTime }}</div>
                  </div>
                  <div class="item_orderClass" v-if="item.orderChannel == 1">
                    <img
                      src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Group 3973.png"
                      alt=""
                    />
                    <span>平台订单</span>
                  </div>
                  <div class="item_orderClass" v-if="item.orderChannel == 2">
                    <img
                      src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/展厅-5 3.png"
                      alt=""
                    />
                    <span>小程序订单</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="storeCenter_item_top_center">
              <el-steps
                :space="item.orderScheduleListGinsengResultList.length == 3 ? 360 : 200"
                :style="
                  item.orderScheduleListGinsengResultList.length == 3
                    ? 'position: relative;right: 50px;'
                    : ''
                "
                align-center
                :active="item.schedule"
              >
                <el-step
                  icon=""
                  v-for="(el, indexs) in item.orderScheduleListGinsengResultList"
                  :key="indexs"
                >
                  <template slot="title">
                    <span class="codeName_size">{{ el.codeName }}</span>
                    <img
                      class="file_pdf"
                      @click="openPdf(el.scheduleFile)"
                      v-if="el.scheduleFile"
                      src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/24gf-folderOpen.png"
                      alt=""
                    />
                  </template>
                  <template slot="description">
                    <span>{{ el.createTime }}</span>
                  </template>
                </el-step>
              </el-steps>
            </div>

            <div class="storeCenter_item_top_right">
              <div class="operation_area">
                <span class="edit" @click="editinfo(item)">详情</span>
              </div>
            </div>

            <div class="Relevancy" v-if="item.enterpriseCategory == '传统企业'">
              <span>{{ item.enterpriseCategory }}</span>
            </div>
            <div class="Relevancyblue" v-else-if="item.enterpriseCategory != null">
              <span>{{ item.enterpriseCategory }}</span>
            </div>
          </div>
        </div>
        <div class="new_page" v-if="listData.length">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <div class="workbench" v-else>
        <img src="../../../assets/image/数据内容空 1.png" alt="" />
        <div class="workbench_trips">
          <span>暂无订单</span>
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="dialogVisibleinfo" width="50%">
      <div class="orderinfo_div">
        <div class="storeCenter_item_top_left_order">
          <div class="order_name am" v-if="orderinfo.orderType == '艾媒订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name rc" v-if="orderinfo.orderType == '人才订单'">
            <!-- {{ orderinfo.orderType }} -->
            人才服务
          </div>
          <div class="order_name xs" v-if="orderinfo.orderType == '线索订单'">
            <!-- {{ orderinfo.orderType }} -->
            甄选对接
          </div>
          <div class="order_name zj" v-if="orderinfo.orderType == '专家订单'">
            <!-- {{ orderinfo.orderType }} -->
            专家咨询
          </div>
          <div class="order_name hd" v-if="orderinfo.orderType == '活动订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name zd" v-if="orderinfo.orderType == '诊断订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name zd" v-if="orderinfo.orderType == '混合订单'">
            {{ orderinfo.orderType }}
          </div>

          <div class="order_name hy" v-if="orderinfo.orderType == '个人会员订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name hy" v-if="orderinfo.orderType == '企业会员订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name bg" v-if="orderinfo.orderType == '报告订单'">
            {{ orderinfo.orderType }}
          </div>

          <div class="order_num">{{ orderinfo.orderNumber }}</div>
        </div>
        <div class="storeCenter_item_top_left_order" style="margin-top: 10px">
          <div class="compny_name">订单名称：</div>
          <div>{{ orderinfo.orderName }}</div>
        </div>
        <div class="storeCenter_item_top_left_order" style="padding: 8px 0px">
          <div class="compny_name">付款人/企业：</div>
          <div>{{ orderinfo.realName }}</div>
          <div v-if="orderinfo.realName && orderinfo.companyName">/</div>
          <div>
            {{ orderinfo.companyName }}
          </div>
        </div>

        <div class="storeCenter_item_top_left_order">
          <div class="storeCenter_item_top_left_order">
            <div class="compny_name">创建时间：</div>
            <div>{{ orderinfo.createTime }}</div>
          </div>
          <div class="item_orderClass" v-if="orderinfo.orderChannel == 1">
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Group 3973.png"
              alt=""
            />
            <span>平台订单</span>
          </div>
          <div class="item_orderClass" v-if="orderinfo.orderChannel == 2">
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/展厅-5 3.png"
              alt=""
            />
            <span>小程序订单</span>
          </div>
        </div>
      </div>

      <el-table
        ref="multipleTables"
        v-loading="loading"
        :data="ScheduleList"
        stripe
        width="100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column label="订单进度" prop="orderSchedule" align="center"></el-table-column>
        <el-table-column label="进度描述" prop="" align="center">
          <template v-slot="{ row }">
            <span>{{ row.scheduleDescribe }}</span>
          </template>
        </el-table-column>
        <el-table-column label="进度附件" prop="scheduleFile" align="center"></el-table-column>

        <el-table-column label="添加人" prop="userName" align="center"></el-table-column>
        <el-table-column
          label="添加时间"
          sortable
          prop="createTime"
          align="center"
        ></el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="orderSchedule.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="orderSchedule.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totaldins"
          @size-change="handleSize"
          @current-change="handleCurrent"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { orderCompanyListPage, listById, orderScheduleListPage } from '@/api/order'
import { getCompanyDetail } from '@/api/datement'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  companyId: '', //企业编号
  userSchema: '', //合作伙伴编号
  keys: '', //订单编号/付款公司/付款人
  orderChannel: '', //订单渠道；1代表平台订单，2代表渠道订单（或小程序订单
  orderMember: '', //会员订单
  orderSchedule: '', //订单进度 1->执行中;2-已完结;3->已终止
  orderType: '', //订单类型；活动订单，专家订单,报告订单等(除会员订单)
  orders: '1' //排序 默认传1 2->创建时间升序;3->创建时间降序;4->支付金额升序;5->支付金额降序
})
export default {
  name: 'order',
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      ids: [],
      idsList: [],
      queryInfo: { ...defaultQueryInfo },
      parteninfo: JSON.parse(sessionStorage.getItem('typeMode')),
      listData: [],
      // 遮罩层
      loading: false,
      dialogVisibleinfo: false, //详情
      totaldins: 0,
      orderinfo: {}, //订单详情
      ScheduleList: [], //订单的订单进度一览
      orderSchedule: {
        orderId: '',
        pageNum: 1,
        pageSize: 10,
        orders: 1
      },
      // 总条数
      total: 0,
      orderList: [
        {
          name: '按创建时间',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc: 3
        },
        {
          name: '按支付金额',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 5
        }
      ], //排序
      companyinfo: {} //企业信息
    }
  },
  created() {
    this.search()
    this.getcompany()
  },

  methods: {
    //查询企业信息回显
    async getcompany() {
      const query = { id: this.id }
      const res = await getCompanyDetail(query)
      if (res.data.resultCode == 200) {
        this.companyinfo = res.data.data
      }
    },
    //详情
    editinfo(item) {
      this.dialogVisibleinfo = true
      this.listById(item.id)
      this.orderSchedule.orderId = item.id
      this.orderScheduleListPage()
    },
    //订单详情
    async listById(orderId) {
      const query = {
        orderId
      }
      const { data: res } = await listById(query)
      if (res.resultCode == 200) {
        this.orderinfo = res.data
      }
    },
    //订单进度
    async orderScheduleListPage() {
      const { data: res } = await orderScheduleListPage(this.orderSchedule)
      if (res.resultCode == 200) {
        this.ScheduleList = res.data.list
        this.ScheduleList.forEach((el) => {
          if (el.scheduleFile) {
            var index = el.scheduleFile.lastIndexOf('/')
            el.scheduleFile = el.scheduleFile.substring(index + 1, el.scheduleFile.length)
          }
        })
        this.totaldins = res.data.total
      }
    },
    //订单类型选择
    orderTypechange(val) {
      if (!val) {
        this.queryInfo.orderMember = ''
        this.queryInfo.state = ''
      }
      if (val == '会员订单') {
        this.queryInfo.orderType = null
      } else {
        this.queryInfo.orderMember = ''
      }
      if (val == '1') {
        this.queryInfo.orderSchedule = null
      } else {
        this.queryInfo.state = ''
      }
      this.search()
    },
    //查询订单
    async search() {
      this.queryInfo.companyId = this.id
      this.queryInfo.userSchema = this.parteninfo.id
      this.loading = true
      const res = await orderCompanyListPage(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list

        this.total = res.data.data.total
        this.loading = false
        this.listData.forEach((item) => {
          this.$set(item, 'checked1', false)
          this.$set(item, 'schedule', 1)
          if (item.orderScheduleListGinsengResultList.length) {
            item.orderScheduleListGinsengResultList.forEach((el, index) => {
              if (item.orderScheduleListGinsengResultList[index].createTime) {
                // this.$set(item, 'schedule', index+1)
                item.schedule = index + 1
              }
            })
          }
        })
      }
    },
    //升序排序
    clickUp1(item, index) {
      ;(this.orderList = [
        {
          name: '按创建时间',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc: 3
        },
        {
          name: '按支付金额',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 5
        }
      ]), //排序
        (this.queryInfo.orders = item.asc)
      this.search()
      this.orderList[index].color1 = '#0099ff'
      this.orderList[index].color2 = ''
    },
    //降序排序
    clickDown1(item, index) {
      ;(this.orderList = [
        {
          name: '按创建时间',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc: 3
        },
        {
          name: '按支付金额',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 5
        }
      ]), //排序
        (this.queryInfo.orders = item.desc)
      this.search()
      this.orderList[index].color1 = ''
      this.orderList[index].color2 = '#0099ff'
    },
    //查看附件
    openPdf(val) {
      window.open(val)
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 分页
    handleSize(val) {
      this.orderSchedule.pageSize = val
      this.orderScheduleListPage()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrent(val) {
      this.orderSchedule.pageNum = val
      this.orderScheduleListPage()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 0;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}
::v-deep .el-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}
::v-deep .width_input .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 500px;
}
::v-deep .select_input .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 200px;
}
::v-deep .width_input .el-textarea {
  position: relative;
  display: inline-block;
  width: 500px;
  vertical-align: bottom;
  font-size: 14px;
}

::v-deep .select_input .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 200px;
}
::v-deep .el-step__title.is-process {
  font-weight: 700;
  color: #c0c4cc;
}
::v-deep .el-step__head.is-process {
  color: #c0c4cc;
  border-color: #c0c4cc;
}
::v-deep .el-step__icon-inner {
  display: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  color: inherit;
}
::v-deep .el-step__icon.is-text {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid;
  border-color: inherit;
}

.user {
  .orderinfo_div {
    padding: 20px;
    margin-bottom: 20px;
    background: #f9f9f9;
  }
  .order_top {
    background: #ffffff;
    padding: 20px;
    .order_top_name {
      font-size: 18px;
      font-weight: 550;
      padding-bottom: 20px;
    }
    .order_top_flex {
      display: flex;
      align-items: flex-end;
      position: relative;
      .po_compny {
        position: absolute;
        color: #4e93fb;
        font-size: 18px;
        font-weight: 550;
        left: 140px;
        top: 0px;
      }
      .img_radius {
        width: 90px;
        height: 90px;
        border-radius: 90px;
      }
      .order_top_flex_top {
        display: flex;
        align-items: center;
        .blue_money {
          color: #0066ff;
          font-size: 28px;
          font-weight: 550;
        }
        .hui_money {
          color: #999999;
        }
      }
      .order_top_flex_bottom {
        .hui_money {
          color: #999999;
        }
      }
      .order_top_flex_left {
        padding-left: 20px;
        line-height: 50px;
      }
      .order_top_flex_right {
        padding-left: 80px;
        line-height: 50px;
      }
    }
    .border_left {
      height: 50px;
      border-left: 2px solid #cccccc;
      margin-left: 50px;
      padding-left: 50px;
    }
    .round_order {
      width: 95px;
      height: 95px;
      border: 10px solid #4e93fb;
      border-radius: 95px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .order_num {
        margin-top: 10px;
        font-weight: 550;
        font-size: 20px;
      }
    }
    .round_order_right {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0px 30px;
      line-height: 40px;
      .order_num {
        margin-top: 10px;
        font-weight: 550;
        font-size: 20px;
      }
      .order_hui {
        margin-top: 10px;
        font-size: 20px;
        color: #9b9a9a;
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  .width_input {
    .orderName_flex {
      position: relative;
      display: flex;
      align-items: center;
      width: 500px;
      .po_ordernum {
        position: absolute;
        right: 10px;
        background: #edf4ff;
        border-radius: 4px;
        height: 30px;
        width: 140px;
        padding: 0px 5px;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .icon_flex {
    display: flex;
    align-items: center;
    .scheduleFile {
      width: 300px;
      margin-left: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .form_info {
    background: #ffffff;
    padding: 20px 20px 5px 20px;
    margin-top: 20px;
  }

  .tab_txt {
    margin-top: 20px;
    padding: 0px;
    .workbench {
      height: 500px;
      width: 100%;
      background: #fff;
      position: relative;
      img {
        width: 303px;
        height: 303px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .workbench_trips {
        position: absolute;

        color: #999999;
        font-size: 18px;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .main {
      display: flex;
      flex-direction: column;
    }

    .arrUp {
      width: 0;
      height: 0;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 8px solid #c6c6c6;
      cursor: pointer;
    }

    .arrDown {
      width: 0;
      height: 0;
      margin-top: 2px;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 8px solid #c6c6c6;
      cursor: pointer;
    }
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;
        width: 35%;
      }
      .storeCenter_item_top_center {
        width: 1000px;
        .codeName_size {
          margin-right: 3px;
          font-size: 14px;
        }
        .file_pdf {
          width: 13px;
          height: 13px;
          position: relative;
          top: 1px;
          cursor: pointer;
        }
      }
      .storeCenter_item_top_right {
        width: 15%;
        align-items: stretch;
        height: 100%;
        .account_cycle {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;

          /* 灰色字体 */

          color: #969495;
        }
        .operation_area {
          margin-top: 30px;
          float: right;
          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .edit {
            padding-left: 20px;
            color: #4e93fb;
            cursor: pointer;
          }
          .frozen {
            padding-left: 20px;
            color: #fd5469;
            cursor: pointer;
          }
        }
      }
    }
  }
  .storeCenter_item_top_left_order {
    display: flex;
    align-items: center;
    font-size: 14px;
    .state_one {
      width: 50px;
      height: 18px;
      color: #e00000;
      background: #fce5e5;
      border-radius: 8px;
      font-size: 10px;
      text-align: center;
      line-height: 18px;
      margin-left: 5px;
    }
    .state_two {
      font-weight: 550;
      color: #ff7d18;
      margin-left: 5px;
    }
    .item_orderClass {
      margin-left: 10px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #6e6e6e;
      img {
        margin-right: 5px;
        width: 14px;
        height: 14px;
      }
    }
    .order_name {
      border-radius: 4px;
      padding: 5px;
    }
    .am {
      background: #f4edff;
      color: #904efb;
    }
    .rc {
      background: #e7fce5;
      color: #12dc00;
    }
    .xs {
      background: #edf4ff;
      color: #4e93fb;
    }
    .zj {
      background: #fff2e8;
      color: #ff7d18;
    }
    .hd {
      background: #feebeb;
      color: #f53939;
    }
    .zd {
      background: #e5f2f8;
      color: #0079bd;
    }
    .hy {
      background: #fbf5f0;
      color: #d39869;
    }
    .bg {
      color: #df08bd;
      background: #fce6f8;
    }
    .order_num {
      color: #333333;
      font-size: 18px;
      font-weight: 550;
      margin-left: 10px;
    }
    .compny_name {
      color: #999999;
      font-size: 14px;
    }
  }
}
</style>
